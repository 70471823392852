/* Modules */
import { call, put, takeEvery } from 'redux-saga/effects';
import dayjs from 'dayjs';

// Services
import { SchemaService, InsightsService } from '@services';

/* Types */
import { getTimezone } from '@utils/helpers';
import {
  GET_BUDGET_GROUP,
  CHANGE_BUDGET,
  CHANGE_BUDGET_AVAILABLE,
  GET_BUDGET_ACCOUNT,
  POST_DEPOSIT,
  POST_WITHDRAW,
  POST_RESET,
} from './types';

import { SHOW_TOAST } from '../toast/types';

// Utils

function* getBudgetGroup(action) {
  const accountIds = yield call(
    InsightsService.getAccountIds,
    '1.1',
    action.code,
    '?fields=id,name,type,budget',
    '["group"]',
    action.budgetId
  );
  let current;
  let currentInfo;
  let currentItem;
  let cards = {};
  const list = [];

  if (action.code === 'root') {
    current = yield call(SchemaService.current, '1.1');
    if (current.status === 200) {
      cards = {
        total: current.data.total,
        allocated: current.data.allocated,
        remaining: current.data.remaining,
      };
    }
  } else {
    currentInfo = yield call(SchemaService.getCodeInformation, '1.1', action.code);
    currentItem = yield call(SchemaService.current, '1.1');

    if (currentInfo.status === 200) {
      cards = {
        total: currentInfo.data.budget ? currentInfo.data.budget.total : 0,
        allocated: currentInfo.data.budget ? currentInfo.data.budget.allocated : 0,
        remaining: currentInfo.data.budget ? currentInfo.data.budget.remaining : 0,
      };
    }
  }

  const myCurrent = action.code === 'root' ? current.data : currentItem.data;
  const dateStart = dayjs(new Date(myCurrent.start_date)).format('YYYY-MM-DD');
  const dateEnd = dayjs(new Date(myCurrent.end_date)).format('YYYY-MM-DD');

  if (accountIds.status === 200) {
    const arrayToPost = [];

    if (accountIds.data.length) {
      accountIds.data.forEach((item) => {
        arrayToPost.push({
          grouped_by: item.source_id,
          name: item.name,
          spend: 0,
          budget: item.budget
            ? item.budget
            : {
              total: 0,
              remaining: 0,
              allocated: 0,
              business_budget_id: '',
            },
          metrics: [
            { name: 'spent', value: 0 },
            { name: 'bar', value: 0 },
            { name: 'budget', value: 0 },
          ],
          currency: 'BRL',
          source_ids: [item.source_id],
          type: item.type,
        });
      });
    }

    const postListAll = yield call(
      InsightsService.postListAll,
      '1.1',
      `?since=${dateStart}&until=${dateEnd}&timezone_offset=${getTimezone()}&fields=spend&`,
      arrayToPost
    );

    if (postListAll.status === 200) {
      postListAll.data.data.forEach((post) => {
        list.push({
          source_id: post.source_id,
          name: arrayToPost.find((x) => x.grouped_by === post.source_id).name,
          spent: post.spend || 0,
          remaining:
            arrayToPost.find((x) => x.grouped_by === post.source_id).budget.total - post.spend || 0,
          budget_id: myCurrent.id,
          progress:
            (post.spend / arrayToPost.find((x) => x.grouped_by === post.source_id).budget.total) *
            100 || 0,
          budget: arrayToPost.find((x) => x.grouped_by === post.source_id).budget.total || 0,
        });
      });

      yield put({
        type: GET_BUDGET_GROUP.SUCCESS,
        cards,
        list,
        status: postListAll.status,
      });
    } else {
      yield put({
        type: GET_BUDGET_GROUP.FAILURE,
        message: 'Response error',
        status: postListAll.status,
      });
    }
  } else {
    yield put({
      type: GET_BUDGET_GROUP.FAILURE,
      message: 'Response error',
      status: accountIds.status,
    });
  }
}

function* changeBudget(action) {
  const response = yield call(
    SchemaService.changeMontlyGoal,
    action.version,
    action.urlId,
    action.body
  );

  if (response.status === 200) {
    yield put({
      type: CHANGE_BUDGET.SUCCESS,
      response: response.data,
      newValue: action.body.value,
    });
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.successMessage, messageType: 'success' });
  } else {
    if (response.data.error === 'BUSY_BUDGET') {
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: action.errorMessageWait,
        messageType: 'error',
      });
    } else if (response.data.error === 'INSUFFICIENT_BUDGET') {
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: action.errorMessageBudget,
        messageType: 'error',
      });
    } else if (response.data.error === 'INVALID_BUDGET_ENTITY') {
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: action.errorMessageInvalid,
        messageType: 'error',
      });
    } else if (response.data.error === 'BATCH_BUGET_COOLDOWN_PERIOD') {
      const timeLeft =
        response.data.timeLeft != null ? response.data.time_left.slice(0, 5) : '00:00';
      const messageError = action.errorMessageCooldown + timeLeft;
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: messageError,
        messageType: 'error',
      });
    } else {
      yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
    }
    yield put({ type: CHANGE_BUDGET.FAILURE, response: response.data.error });
  }
}

function* changeBudgetAvailable(action) {
  const response = yield call(
    SchemaService.changeBudgetAvailable,
    action.version,
    action.data,
    action.fundingId
  );

  if (response.status === 200) {
    yield put({
      type: CHANGE_BUDGET_AVAILABLE.SUCCESS,
      status: response.status,
      newValue: action.data.value,
    });
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.successMessage, messageType: 'success' });
  } else {
    if (response.data.error === 'BUSY_BUDGET') {
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: action.errorMessageWait,
        messageType: 'error',
      });
    } else if (response.data.error === 'INSUFFICIENT_BUDGET') {
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: action.errorMessageBudget,
        messageType: 'error',
      });
    } else if (response.data.error === 'INVALID_BUDGET_ENTITY') {
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: action.errorMessageInvalid,
        messageType: 'error',
      });
    } else if (response.data.error === 'BATCH_BUGET_COOLDOWN_PERIOD') {
      const timeLeft =
        response.data.timeLeft != null ? response.data.time_left.slice(0, 5) : '00:00';
      const messageError = action.errorMessageCooldown + timeLeft;
      yield put({
        type: SHOW_TOAST.SUCCESS,
        message: messageError,
        messageType: 'error',
      });
    } else {
      yield put({ type: SHOW_TOAST.SUCCESS, message: action.errorMessage, messageType: 'error' });
    }
    yield put({ type: CHANGE_BUDGET_AVAILABLE.FAILURE, status: response.status });
  }
}

function* getBudgetAccount(action) {
  const accountIds = yield call(
    SchemaService.getAdAccounts,
    '1.1',
    action.code,
    '?fields=name,budget',
    '["group"]'
  );
  const current = yield call(SchemaService.current, '1.1');
  const list = [];

  const myCurrent = current.data;
  const dateStart = dayjs(new Date(myCurrent.start_date)).format('YYYY-MM-DD');
  const dateEnd = dayjs(new Date(myCurrent.end_date)).format('YYYY-MM-DD');

  if (accountIds.status === 200) {
    const arrayToPost = [];
    const arrayToMix = [];

    if (accountIds.data.length) {
      accountIds.data.forEach((item) => {
        arrayToPost.push({
          grouped_by: item.source_id,
          spend: 0,
          currency: 'BRL',
          source_ids: [item.source_id],
        });

        arrayToMix.push({
          grouped_by: item.source_id,
          name: item.name,
          budget: {
            total: item.budget ? item.budget.total : 0,
            business_budget_id: item.budget ? item.budget.business_budget_id : 0,
          },
        });
      });
    }

    const postListAll = yield call(
      InsightsService.postListAll,
      '1.1',
      `?since=${dateStart}&until=${dateEnd}&timezone_offset=${getTimezone()}&fields=spend`,
      arrayToPost
    );

    if (postListAll.status === 200) {
      postListAll.data.data.forEach((post) => {
        list.push({
          source_id: post.source_id,
          name: arrayToMix.find((x) => x.grouped_by === post.source_id).name,
          spent: post.spend || 0,
          remaining:
            arrayToMix.find((x) => x.grouped_by === post.source_id).budget.total -
            (post.spend || 0),
          budget_id: arrayToMix.find((x) => x.grouped_by === post.source_id).budget
            .business_budget_id,
          budget: arrayToMix.find((x) => x.grouped_by === post.source_id).budget.total || 0,
        });
      });

      yield put({ type: GET_BUDGET_ACCOUNT.SUCCESS, list, status: postListAll.status });
    } else {
      yield put({
        type: GET_BUDGET_ACCOUNT.FAILURE,
        message: 'Response error',
        status: postListAll.status,
      });
    }
  } else {
    yield put({
      type: GET_BUDGET_ACCOUNT.FAILURE,
      message: 'Response error',
      status: accountIds.status,
    });
  }
}

function* postDeposit(action) {
  const response = yield call(SchemaService.postDeposit, action.version, action.id, action.data);

  if (response.status === 200) {
    yield put({ type: POST_DEPOSIT.SUCCESS, deposit: 'success', data: action.data });
    yield put({ type: SHOW_TOAST.SUCCESS, message: action.successMessage, messageType: 'success' });
  } else {
    const responseDataError = response.data.error;

    let { errorMessage } = action;

    if (responseDataError === 'INSUFFICIENT_BUDGET') {
      errorMessage = action.errorInsuficientBudget;
    } else if (responseDataError === 'DISABLED_AD_ACCOUNT') {
      errorMessage = action.errorDisabledAccount;
    }

    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: errorMessage,
      messageType: 'error',
    });
    yield put({ type: POST_DEPOSIT.FAILURE, depositError: response });
  }
}

function* postWithdraw(action) {
  const response = yield call(SchemaService.postWithdraw, action.version, action.id, action.data);

  if (response.status === 200) {
    yield put({ type: POST_WITHDRAW.SUCCESS, withdraw: 'success', data: action.data });
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.successMessage,
      messageType: 'success',
      id: action.id,
    });
  } else {
    const responseDataError = response.data.error;

    let { errorMessage } = action;

    if (responseDataError === 'INSUFFICIENT_BUDGET') {
      errorMessage = action.errorInsuficientBudget;
    } else if (responseDataError === 'DISABLED_AD_ACCOUNT') {
      errorMessage = action.errorDisabledAccount;
    }

    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: errorMessage,
      messageType: 'error',
    });
    yield put({ type: POST_WITHDRAW.FAILURE, withdrawError: response.data.error });
  }
}

function* postReset(action) {
  const response = yield call(SchemaService.resetBudget, action.data, action.id);

  if (response.status === 200) {
    yield put({ type: POST_RESET.SUCCESS, withdraw: 'success', data: action.data });
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.successMessage,
      messageType: 'success',
      id: action.id,
    });
  } else {
    yield put({
      type: SHOW_TOAST.SUCCESS,
      message: action.errorMessage,
      messageType: 'error',
      id: action.id,
    });
    yield put({ type: POST_WITHDRAW.FAILURE, withdrawError: response.data.error });
  }
}

export const budgetSaga = [
  takeEvery(GET_BUDGET_GROUP.REQUEST, getBudgetGroup),
  takeEvery(CHANGE_BUDGET.REQUEST, changeBudget),
  takeEvery(CHANGE_BUDGET_AVAILABLE.REQUEST, changeBudgetAvailable),
  takeEvery(GET_BUDGET_ACCOUNT.REQUEST, getBudgetAccount),
  takeEvery(POST_DEPOSIT.REQUEST, postDeposit),
  takeEvery(POST_WITHDRAW.REQUEST, postWithdraw),
  takeEvery(POST_RESET.REQUEST, postReset)
];
